<template>
	<div class="potential contractDetail">
		<div ref="navBar">
			<rxNavBar title="通知详情"></rxNavBar>
		</div>
		<div class="panel withoutMargin">

			<div class="title">
				<p class="van-multi-ellipsis--l2">{{inform.title}}</p>
			</div>
			<van-row class="info">
				<van-col span="14">
					<p>

						<span>{{inform.addTime}}</span>
					</p>
				</van-col>
				<van-col span="10">
					<p>

						<span>{{inform.addStaffName}}</span>
					</p>
				</van-col>
			</van-row>
			<div class="info">
				<p>
					<span>概要：{{inform.content}}</span>
				</p>				
				<p>
					<span>正文：{{inform.introduction}}</span>
				</p>
			</div>
		</div>
        <div class="bottom" >
            <div @click="dealFinish" class="bottom-change" v-if="inform.type=='2'&&!haveFinish&&inform.dealtStatus!='已办'">待办完成</div>                           
        </div>
        <van-popup v-model="isEdit" position="bottom" >
            <div style="background-color: #FAFAFA; overflow: hidden">
                <div class="popupTitle"><span>处理意见</span></div>
                <div class="container-projectCost">
                    <div class="addPropertyDetail">
                        <textarea v-model="dealOpinion" class="textareaRemarks"></textarea>
                    </div>
                </div> 
				<div style="text-align:right">                   
                <van-button class="saveButton_Enable" @click="saveDeal()">保存</van-button>
				</div>
            </div>
        </van-popup>				
	</div>
</template>
<script>
	import BScroll from "better-scroll";
	import {
		NavBar,
		Tab,
		Tabs,
		Row,
		Col,
		Divider,
		Tag,
		Image as VanImage,
		Button,
		ImagePreview,
		Popup,
		Toast
	} from "vant";
    import {
       setBedealt
    } from "../../../getData/getData";
    import {responseUtil,  globaluserId} from "../../../libs/rongxunUtil";	
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

	/********判断H5页面是否在web容器中 start*********/
	export default {
		data() {
			return {
               inform:{ 
                time:'',
                title:'',
                creatorName:'',
                content:'',
                state:'',
                type:'',
                noticeStatus:'',
				id:'',
				introduction:''
               },
			   isEdit:false,      //是否弹出处理意见页面
			   dealOpinion:'',    //处理意见
			   haveFinish:false,
			}
		},
		created() {
			let addTime = this.$route.query.addTime
			this.inform.addTime=addTime.split(' ')[0]
			this.inform.title= this.$route.query.title
			this.inform.addStaffName = this.$route.query.addStaffName
			this.inform.content = this.$route.query.content
            this.inform.state=this.$route.query.content
            this.inform.type=this.$route.query.type
            this.inform.noticeStatus=this.$route.query.noticeStatus
			this.inform.id=this.$route.query.id
			this.inform.introduction=this.$route.query.introduction
			this.inform.dealtStatus=this.$route.query.dealtStatus
		},
		mounted(){
            //this.haveRead()
			this.saveDeal()
		},
		methods: {
			dealFinish(){
                this.isEdit=true
			},
			saveDeal(){
				console.log(this.inform)
			   if(this.inform.noticeStatus=='已读'||this.inform.noticeStatus=='已办'){
                  return
			   }				
                if(!this.dealOpinion&&this.inform.type=='2'){					
					if(this.isEdit){
                        responseUtil.alertMsg(this,'请填写处理意见')
					}
					return
				}
                let that=this
                let queryData={}
                queryData.user_id=globaluserId()
				queryData.type=that.inform.type
				queryData.id=that.inform.id
				queryData.content=that.dealOpinion
				console.log(queryData)
                setBedealt(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
						if(that.inform.type=='2'&&response.data.code=='0'){
                            that.isEdit=false
							that.haveFinish=true
							responseUtil.alertMsg(that, '操作成功')
						}
						
                    })
                })				
			},
           //将未读消息变为已读
		   haveRead(){
			   return
			   if(this.inform.noticeStatus=='已读'||this.inform.noticeStatus=='已办'){
                  return
			   }
                let that=this
                let queryData={}
                queryData.user_id=globaluserId()
				queryData.type=that.inform.type
				queryData.id=that.inform.id
				console.log(queryData)
                setBedealt(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                    })
                })            
		   },
		},
		components: {
			[NavBar.name]: NavBar,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Row.name]: Row,
			[Col.name]: Col,
			[Tag.name]: Tag,
			[Divider.name]: Divider,
			[VanImage.name]: VanImage,
			[Button.name]: Button,
			[BScroll.name]: BScroll,
			[ImagePreview.name]: ImagePreview,
			[Popup.name]: Popup,
			rxNavBar,

		}
	};
</script>
<style lang="less" scoped>
	@redius: 8px;
	@spanColor: rgba(102, 102, 102, 1);

	.tu_hide {
		display: none;
		width: 19px;
		height: 19px;
		float: right;
	}

	.tu_show {
		display: block;
		width: 19px;
		height: 19px;
		float: right;
	}

	.unpay {
		width: 20px;
		height: 20px;
		float: right;
	}

	.potential {
		width: 100%;
		background-color: #f8f8f8;
		font-size: 12px;
		box-sizing: border-box;
		overflow: hidden;

		p {
			margin: 0;
		}

		span {
			&.hasPadding {
				padding: 0 5px;
			}
		}

		.panel {
			/*width: 345px;*/
			width: 92%;
			margin: 15px auto;
			background-color: rgb(255, 255, 255);
			border-radius: @redius;
			position: relative;
			font-size: 15px;
			text-align: left;
			overflow: hidden;
			padding: 15px;
			box-sizing: border-box;

			&.withoutMargin {
				margin: 3px auto;
			}

			p {
				margin: 6px 0;
				font-weight: bolder;

				span {
					font-weight: normal;
					color: rgb(151, 151, 151);
				}
			}

			.state {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 20px;
				font-weight: bold;
				height: 31px;
				line-height: 33px;
				width: 60px;
				display: block;
				background-color: rgb(163, 153, 153);
				border-top-left-radius: 16px;
				border-bottom-right-radius: 16px;
				color: rgb(255, 255, 255);
				text-align: center;
				transform: scale(0.5);
				transform-origin: 0% 0%;
				font-family: sans-serif;
				padding: 0 10px;


				&.stateGreen {
					background-image: linear-gradient(to right, #66CB94, #28D3B0);
				}

				&.stateRed {
					background-image: linear-gradient(to right, #E02020, #FF431B);
				}

				&.stateGray {
					background-image: linear-gradient(to right, #999999, #999999);
				}

				&.stateOrange {
					background-image: grey
				}
			}

			.title {
				font-weight: bolder;
				color: rgb(0, 0, 0);
				font-size: 15px;
				/*padding: 20px 15px 15px;*/
				border-bottom: 1px solid rgb(250, 250, 250);

				span {
					font-weight: normal;
					color: rgba(153, 153, 153);
				}
			}

			.info {
				/*padding: 15px;*/
				padding-top: 12px;
				padding-bottom: 12px;
				border-bottom: 1px solid rgb(250, 250, 250);

				&:first-of-type {
					padding-top: 0;
				}

				&:last-of-type {
					border-bottom: none;
					padding-bottom: 0;
				}

				.tenant {
					font-size: 18px;
					display: flex;
					align-items: center;
					.male {
						font-size: 12px;
						color: rgba(86, 155, 236, 1);
						margin-left: 10px;
					}

					.female {
						font-size: 12px;
						color: rgba(255, 140, 219, 1);
						margin-left: 10px;
					}
				}

				.DO_link {
					float: right;
					color: rgba(86, 155, 236, 1);
					cursor: pointer;
					font-size: 12px;
				}
			}

			&.refund {
				p {
					line-height: 24px;
					padding: 0;
					margin: 0;
				}
			}
		}

		.span-description {
			font-size: 15px;
			font-weight: normal;
			color: rgba(153, 153, 153);
		}

		.bill {
			margin-top: 15px;

			.bill_title {
				font-size: 12px;
				background-color: rgb(241, 241, 241);
				color: rgb(155, 155, 155);
				padding: 1px 15px;
			}

			.bill_panel {
				background-color: rgb(255, 255, 255);


				.bill_info {
					position: relative;

					/*padding: 15px;*/
					padding: 0 15px;
					border-bottom: 1px solid rgb(250, 250, 250);

					.img_icon {
						position: absolute;
						top: 0;
						right: -6px;
					}

					&:nth-last-child {
						border-bottom: none;
					}

					.bill_money {
						font-size: 15px;

						&.unreceived {
							opacity: .3;
						}
					}

					p {
						font-weight: bolder;
						font-size: 14px;
						padding-top: 11px;

						&:last-of-type {
							padding-bottom: 10px;
						}
					}

					span {
						font-weight: normal;
						color: rgb(151, 151, 151);
					}
				}
			}
		}

		.billPayTime{
			color: rgb(151, 151, 151);
			height: 18px;
			line-height: 18px;
		}

		.application_time {
			text-align: right;
			width: 345px;
			font-size: 12px;
			color: rgb(136, 136, 136);
			padding: 10px 0 5px;
		}

		.tabWrapper {
			width: 100%;
			overflow: hidden;
			margin-bottom: 95px;
		}

		.bottom_btn {
			margin-left: 20px;
			/*padding:10px 15px;*/
			/*margin-left: 4.25rem;*/
			margin-left: 5.55rem;

			.btn {
				width: 120px;
				margin-top: 10px;
				border-radius: @redius;
				background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
				background: -webkit-linear-gradient(left,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Safari 5.1 - 6.0 */
				background: -o-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Opera 11.1 - 12.0 */
				background: -moz-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Firefox 3.6 - 15 */
				background: linear-gradient(to right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				color: rgb(255, 255, 255);
				font-size: 16px;
			}
		}

		// 图片墙
		.swiper_show {
			width: 325px;
			position: relative;
			margin: 15px 10px;
			height: 75px;

			.content {
				position: absolute;
				display: flex;
				justify-content: space-around;
				left: 0px;

				.imgWrap {
					margin-right: 10px;
				}
			}

			.num {
				position: absolute;
				left: 4px;
				top: 6px;
				font-size: 10px;
				background-color: rgb(0, 0, 0);
				opacity: 0.5;
				color: rgb(255, 255, 255);
				padding: 2px 6px;
				display: inline-block;
				border-radius: 10px;
				font-weight: bolder;
				line-height: 10px;
			}
		}

		// 时间线
		.approve {
			margin-top: 20px;

			.time_line {
				.item {
					display: flex;
					align-items: stretch;
					justify-content: space-between;

					.left {
						width: 20px;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 15px;
						/*margin-right: 5px;*/

						.line {
							width: 1px;
							background-color: rgb(255, 75, 39);
							position: absolute;

							&.line_top {
								top: 0px;
								height: ceil(50%-8px);

								&.first_top {
									background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Safari 5.1 - 6.0 */
									background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Opera 11.1 - 12.0 */
									background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Firefox 3.6 - 15 */
									background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
								}
							}

							&.line_bottom {
								bottom: 0;
								height: ceil(50%+10px);
							}
						}

						.point {
							position: relative;
							width: 9px;
							height: 9px;
							border-radius: 50%;
							border: 1px solid rgb(255, 75, 39);
							transform: translateY(-50%);
							z-index: 99;
							background-color: rgb(255, 255, 255);

							&::after {
								content: '';
								display: block;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								background-color: rgb(255, 75, 39);
								width: 5px;
								height: 5px;
								border-radius: 50%;
							}
						}


					}

					.right {
						width: 100%;
						padding-bottom: 20px;

						.approve_panel {
							margin: 0 15px;
							border-radius: @redius;
							background-color: rgb(255, 255, 255);
							padding: 10px;

							p {
								padding-top: 10px;
								font-weight: bolder;

								&:last-of-type {
									padding-bottom: 10px;
								}

								span {
									font-weight: normal;
									color: rgb(82, 82, 82);
								}
							}
						}
					}
				}

			}
		}

		.small_font {
			font-size: 12px !important;
		}

		.floatR {
			float: right !important;
		}

		.total_color {
			color: rgba(255, 93, 59, 1) !important;
		}
	}

	/* 未收图标 */
	.laber {
		width: 0px;
		height: 0px;
		border-top: 0px solid #FE8D68;
		border-right: 44.6px solid #FE8D68;
		border-bottom: 44.6px solid transparent;
		border-left: 30px solid transparent;
		position: absolute;
		top: 0px;
		right: 0px;


	}

	/* 旋转文字 */
	.laberText {
		width: 33px;
		height: 47px;
		transform: rotate(45deg);
		font-size: 12px;
		color: #ffffff;
	}

	.rentPriceA {
		color: rgba(252, 213, 204, 1);
		font-weight: 500;
	}

	/*免租期样式*/
	.freeTime {
		width: 345px;
		margin: 15px 15px 15px 15px;
		height: auto;
		border-radius: 8px;
		background-color: white;
		overflow: hidden;
	}

	.totalTime {
		width: 345px;
		margin: 0 15px 15px 15px;
		height: 45px;
		border-radius: 8px;
		background-color: white;
		display: flex;
		align-items: center;
	}

	.freeOne {
		height: auto;
		width: 100%;
		border-bottom: 1px solid #f8f8f8;
		overflow: hidden;
	}

	.freeOne-title {
		margin: 5px 0 5px 20px;
		color: red;
		font-weight: bold;
		font-size: 15px;
	}

	.freeOne-title span {
		color: black;
		font-weight: normal;
	}

	.freeOne-time {
		margin: 5px 0 5px 20px;
		color: black;
		font-weight: bold;
		font-size: 15px;
	}

	.freeOne-time span {
		color: black;
		font-weight: normal;
	}

	.totalTitle {
		margin-left: 20px;
		font-weight: bold;
		font-size: 15px;
		height: 20px;
		line-height: 22px;
		width: fit-content;
	}

	.totalLine {
		width: 1px;
		height: 15px;
		margin: 0 8px;
		background-color: gray;
	}

	.totalDays {
		width: 120px;
		height: 20px;
		text-align: left;
		font-size: 15px;
		font-weight: bold;
		line-height: 22px;
		color: red;
	}

	.totalDesc {
		color: gray;
		text-align: left;
		font-size: 15px;
		height: 20px;
		line-height: 22px;
		width: fit-content;
		margin-left: 100px;
	}

	/*交割单标题文字*/
	.contractOrLeaseChange {
		display: inline-block;
		color: red;
		font-weight: bold;
		margin: 15px 0 0 15px;
		font-size: 15px;
	}

	//电子合同
	.electric-contract {
		float: left;
		height: 60px;
		width: 70px;
		margin-top: 0.25rem;
		margin-left: 35px;
		font-size: 14px;
	}

	//电子合同图片
	.electric-contract-photo {
		margin-top: 5px;
		margin-left: 16px;
	}

	.bottom-fixed {
		position: fixed;
		border-top: 1px solid rgb(243, 243, 243);
		width: 100%;
		height: 1.86667rem;
		bottom: 0px;
		background-color: white;
	}

	.bz{
		font-size: 14px;
		font-weight: 800;
		margin-bottom: 5px;
	}
    .bottom{

        height: 60px;
        padding-right: 15px;
        overflow: hidden;
    }

    .bottom-change{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
.popupTitle {
  font-size: 13px;
  color: #ea5600;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  margin-top: 18px;
}	

    .addPropertyDetail{
        width: 92%;
        margin: 0.25rem auto 0.25rem;
        background-color: white;
        border-radius: 0.21333rem;
        padding-bottom: 0.05rem;
        padding-top: 0.05rem;
        /*    备注文本域*/
        .textareaRemarks{
            width: 82%;
            height: 50px;
            margin: 15px auto;
            border-radius: 8px;
            display: block;
            background-color: #f7f7f7;
            border: none;
            font-size: 13px;
            color: #999;
            padding: 15px 15px;
            resize: none;
        }
    } 
    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        margin-right:20px;
        width:100px;
        border-radius: 8px;
       
        font-size: 18px;
        line-height: 1.22rem;
        
        
    } 	
</style>
